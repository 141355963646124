<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Card Reload</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div class="setting-input-container">
                <div class="setting-grid">
                    <div class="setting-input-container-grid">
                        <span class="setting-name" title="Small">Option 1</span>
                        <div class="setting-input-container setting-container-reload" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-reload" v-model="p_reloadOptions.keyReloadNumberSmall" @change="onSave" id="card_reload_settings_small" title="Select reload option 1" aria-label="Select reload option 1">
                                <option v-for="hotkey in p_hotkeys" :key="hotkey.globalId" :value="hotkey.globalId">{{ hotkey.keyCaption }}</option>
                            </select>
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Small)" title="Add new reload option" aria-label="Add new reload option"/>
                                <img src="/images/icons/edit.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="edit(p_reloadOptions.keyReloadNumberSmall)" v-if="p_reloadOptions.keyReloadNumberSmall != null" title="Edit reload option" aria-label="Edit reload reload option"/>
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" title="Medium">Option 2</span>
                        <div class="setting-input-container setting-container-reload" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-reload" v-model="p_reloadOptions.keyReloadNumberMedium" @change="onSave" id="card_reload_settings_medium" title="Select reload option 2" aria-label="Select reload option 2">
                                <option v-for="hotkey in p_hotkeys" :key="hotkey.globalId" :value="hotkey.globalId">{{ hotkey.keyCaption }}</option>
                            </select>
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Medium)" title="Add new reload option" aria-label="Add new reload option"/>
                                <img src="/images/icons/edit.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="edit(p_reloadOptions.keyReloadNumberMedium)" v-if="p_reloadOptions.keyReloadNumberMedium != null" title="Edit reload option" aria-label="Edit reload reload option"/>
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" title="Large">Option 3</span>
                        <div class="setting-input-container setting-container-reload" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-reload" v-model="p_reloadOptions.keyReloadNumberLarge" @change="onSave" id="card_reload_settings_large" title="Select reload option 3" aria-label="Select reload option 3">
                                <option v-for="hotkey in p_hotkeys" :key="hotkey.globalId" :value="hotkey.globalId">{{ hotkey.keyCaption }}</option>
                            </select>
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Large)" title="Add new reload option" aria-label="Add new reload option"/>
                                <img src="/images/icons/edit.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="edit(p_reloadOptions.keyReloadNumberLarge)" v-if="p_reloadOptions.keyReloadNumberLarge != null" title="Edit reload option" aria-label="Edit reload reload option"/>
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" title="Jumbo">Option 4</span>
                        <div class="setting-input-container setting-container-reload" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-reload" v-model="p_reloadOptions.keyReloadNumberKing" @change="onSave" id="card_reload_settings_king" title="Select reload option 4" aria-label="Select reload option 4">
                                <option v-for="hotkey in p_hotkeys" :key="hotkey.globalId" :value="hotkey.globalId">{{ hotkey.keyCaption }}</option>
                            </select>
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.King)" title="Add new reload option" aria-label="Add new reload option"/>
                                <img src="/images/icons/edit.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="edit(p_reloadOptions.keyReloadNumberKing)" v-if="p_reloadOptions.keyReloadNumberKing != null" title="Edit reload option" aria-label="Edit reload reload option"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HotkeyItemModal v-show="hotkeyDialog_show" ref="refHotkeyDialog" :designerType="DesignerTypes.RangeCard" />
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
    </div>
</template>

<script>
import { ref } from "vue";
import { HotKeyTypes, DesignerTypes, BucketSize } from "@/common/enums";
import apiSettings from "@/api/apiSettings";
import HotkeyModel from "@/common/models/HotkeyModel";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import HotkeyItemModal from "@/views/components/Administrator/HotkeyItemModal";
import InputBox from "@/views/components/Shared/InputBox";
import apiHotkey from "@/api/apiHotkey";

export default {
    components: { HotkeyItemModal, InputBox },
    props: ["reloadOptions", "hotkeys"],
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_reloadOptions = ref(props.reloadOptions);
        var validHotkeys = [];

        const refHotkeyDialog = ref(null);
        const hotkeyDialog_show = ref(false);
        const refInputBox = ref(null);
        const showInputBox = ref(false);

        validHotkeys;
        validHotkeys.push(HotKeyTypes.RangeCard);
        validHotkeys.push(HotKeyTypes.RangeCardAuto);
        const p_hotkeys = ref(props.hotkeys.filter((x) => validHotkeys.includes(x.hotKeyType)));

        var emptyModel = new HotkeyModel();
        emptyModel.globalId = null;
        emptyModel.keyCaption = "Empty";
        p_hotkeys.value.unshift(emptyModel);

        onMounted(async () => {
            var toSave = false;
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_reloadOptions.value.keyReloadNumberSmall) == -1) {
                p_reloadOptions.value.keyReloadNumberSmall = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_reloadOptions.value.keyReloadNumberMedium) == -1) {
                p_reloadOptions.value.keyReloadNumberMedium = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_reloadOptions.value.keyReloadNumberLarge) == -1) {
                p_reloadOptions.value.keyReloadNumberLarge = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_reloadOptions.value.keyReloadNumberKing) == -1) {
                p_reloadOptions.value.keyReloadNumberKing = null;
                toSave = true;
            }

            if (toSave) await onSave();
        });

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.saveCardReload(p_reloadOptions.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving bucket settings", err]);
            }
            emitter.emit("server-call-stop");
        };

        const edit = async (hotkeyId) => {
            await refHotkeyDialog.value.showDialog(hotkeyId, async (updatedItem) => {
                if (updatedItem != null) {
                    if (updatedItem == "deleted") {
                        var indexDelete = p_hotkeys.value.findIndex((x) => x.globalId === hotkeyId);
                        if (indexDelete > -1) p_hotkeys.value.splice(indexDelete, 1);
                        if (p_reloadOptions.value.keyReloadNumberSmall == hotkeyId) p_reloadOptions.value.keyReloadNumberSmall = null;
                        if (p_reloadOptions.value.keyReloadNumberMedium == hotkeyId) p_reloadOptions.value.keyReloadNumberMedium = null;
                        if (p_reloadOptions.value.keyReloadNumberLarge == hotkeyId) p_reloadOptions.value.keyReloadNumberLarge = null;
                        if (p_reloadOptions.value.keyReloadNumberKing == hotkeyId) p_reloadOptions.value.keyReloadNumberKing = null;
                        await onSave();
                    } else {
                        var index = p_hotkeys.value.findIndex((x) => x.globalId === updatedItem.globalId);
                        p_hotkeys.value[index] = updatedItem;
                    }
                }
                hotkeyDialog_show.value = false;
            });
            hotkeyDialog_show.value = true;
        };

        const addHotkey = async (size) => {
            refInputBox.value.initWindow("Enter hotkey name", "", 3, 20, async (itemName) => {
                try {
                    emitter.emit("server-call-start", "Creating item...");
                    var model = await apiHotkey.create(itemName);
                    model.hotKeyType = HotKeyTypes.RangeCard;

                    p_hotkeys.value.push(model);

                    switch (size) {
                        case BucketSize.Small:
                            p_reloadOptions.value.keyReloadNumberSmall = model.globalId;
                            break;
                        case BucketSize.Medium:
                            p_reloadOptions.value.keyReloadNumberMedium = model.globalId;
                            break;
                        case BucketSize.Large:
                            p_reloadOptions.value.keyReloadNumberLarge = model.globalId;
                            break;
                        case BucketSize.King:
                            p_reloadOptions.value.keyReloadNumberKing = model.globalId;
                            break;
                    }

                    await apiHotkey.save(model);
                    await apiSettings.saveCardReload(p_reloadOptions.value);
                    await edit(model.globalId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error creating item", err]);
                }
                emitter.emit("server-call-stop");
            });
            showInputBox.value = true;
        };

        return {
            HotKeyTypes,
            p_reloadOptions,
            p_hotkeys,
            onSave,
            DesignerTypes,
            edit,
            refHotkeyDialog,
            hotkeyDialog_show,
            addHotkey,
            refInputBox,
            showInputBox,
            BucketSize
        };
    }
};
</script>

<style>
.setting-input-item-reload {
    width: 200px;
}

.setting-container-reload {
    width: 400px;
}

/* @media (orientation: landscape) { */
@media (pointer: fine), (pointer: none) {
    .setting-grid {
        display: grid;
        grid-template-columns: auto auto;
        width: 50%;
    }
}

/* @media (orientation: portrait) { */
@media (pointer: coarse)  {    
    .setting-grid {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }
}
</style>
