export enum HotKeyTypes {
    Other = -1,
    PinTicketSmall = 0,
    PinTicketMedium = 1,
    PinTicketLarge = 2,
    PinTicketKingSize = 3,
    RangeCard = 4,
    RangeCardAuto = 5,
    SaleItem = 6,
    PinTicketCustom = 7,
    PinTicketCategory = 8,
    TurnstileEntry = 9,
    TBox = 10,
    GiftCard = 11,
    Route = 100,
    Clickable = 101
}

export enum ButtonTypes {
    Route = 100,
    Clickable = 101,
    Action = 101,
    Other = 200
}

export enum DiscountTypes {
    Amount = 0,
    Rate = 1
}

export enum InventoryTypes {
    Inventory = 0,
    PinTicket = 1,
    Reload = 2,
    TBox = 3,
    GiftCard = 4,
    Other = 5,
    OpenRange = 6
}

export enum CustomerRelationshipTypes {
    Unknown = 0,
    Single = 1,
    Parent = 2,
    Child = 3
}

export enum BucketSize {
    Small = 0,
    Medium = 1,
    Large = 2,
    King = 3,
    Free = 4
}

export enum StationTypes {
    Dispenser = 1,
    Turnstile = 2,
    TBox = 3,
    TBoxRAS = 4
}

export enum CategoryStatusType {
    NotActive = 0,
    DiscountAndBonus = 1,
    HappyHour1 = 2,
    HappyHour2 = 3,
    HappyHour3 = 4,
    HappyHour4 = 5
}

export enum CartStatusTypes {
    Unknown = 0,
    NotStarted = 1,
    Processing = 2,
    Paid = 3,
    Rollback = 100
}

export enum CartPaymentTypes {
    Unknown = 0,
    Cash = 1,
    Credit = 2,
    Gift = 3
}

export enum CartPaymentStatusTypes {
    Unknown = 0,
    NotPaid = 1,
    Processing = 2,
    Paid = 3,
    Rollback = 100
}

export enum CartProcessingTypes {
    Sale = 0,
    Refund = 1
}

export enum PosCardReaderTypes {
    Bezel8 = 0,
    Augusta = 1,
    CardPointeTerminal = 2
}

export enum InventoryItemHistoryTypes {
    Unknown = 0,
    Received = 1,
    Adjusted = 2,
    Sold = 3,
    Refunded = 4
}
export enum PaymentProcessorTypes {
    None = 0,
    Pineapple = 1,
    WorldPay = 2
}

export enum DesignerTypes {
    None = 0,
    POS = 1,
    Cashier = 2,
    RangeCard = 3,
    PinTicket = 4
}

export enum PaymentServiceTypes {
    None = 0,
    Pineapple = 1,
    Stripe = 2,
    WorldPay = 3,
    Verifone = 4,
    Adyen = 5
}

export enum CardReaderTypes {
    Unknown = 0,
    Bezel8 = 1,
    DataCap = 2,
    CardPointe = 3,
    VP5300 = 4
}

export enum PulseProfileTypes {
    RangeServant = 0,
    Wittek = 1,
    TimedPitch = 2
}

export enum RefundableStatusTypes {
    Unknown = 0,
    Refundable = 1,
    Voided = 2,
    Refunded = 3
}
