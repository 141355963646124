<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-tenant">
            <div style="display:flex;justify-content:center">New Site</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="tenant-input-container">
                    <span>Site Name</span>
                    <input @focus="clearError" ref="siteName" class="tenant-input-item" type="text" v-model="tenantName" maxlength="75" @keyup="processKeyPress" id="new_tenant_name"/>
                </div>                
                <div class="tenant-input-container">
                    <span>Site Address</span>
                    <input @focus="clearError" class="tenant-input-item" type="text" v-model="address" maxlength="300" @keyup="processKeyPress" id="new_tenant_address"/>
                </div>
                <div class="tenant-input-container" v-if="addressModel != null">
                    <span>Timezone</span>      
                    <div style="width:100%;display: flex; justify-content: flex-end;">
                        <span class="tenant-input-item" style="border: 1px solid green; padding: 5px;color:grey; width:280px">{{ addressModel?.timezone }}</span>
                        <a :href="`https://maps.google.com/?q=${addressModel?.latitude},${addressModel?.longitude}`" target="_blank"><img width="20" src="/images/icons/location-pin.png"/></a>
                    </div>                                   
                </div>
                <div style="color:red;font-size:10pt;text-align:center">
                        {{ errorMessage }}
                    </div>
                <div class="new-tenant-button-bar">
                    <button class="new-tenant-button" @click="onVerifyAddress" id="new_tenant_verify_address" style="margin-right:100px; color:green" v-if="address.length > 0">
                        Verify Address
                    </button>
                    <button class="new-tenant-button" @click="onCreate" id="new_tenant_create_site">
                        Create Site
                    </button>
                    <button class="new-tenant-button" @click="onCancel" ref="btnCancel" id="new_tenant_cancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiTenant from '@/api/apiTenant';


export default {    
    props: ["tenants"],
    setup(props, { emit }) {        
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const btnCancel = ref(null);
        const tenantName = ref('');
        const address = ref('');
        const siteName = ref(null);
        const errorMessage = ref("");
        const addressModel = ref(null);        

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {   
            errorMessage.value = "";            

            // Validate Tenant name
            if ((tenantName.value.length < 6) || (tenantName.value.length > 50)){
                errorMessage.value = "Site name invalid. Must be between 6 and 50 characters.";
                return;
            }

            if ((address.value.length < 6) || (address.value.length > 512)){
                errorMessage.value = "Site address invalid. Must be between 6 and 512 characters.";
                return;
            }

            // Check if tenant already exists
            var exists = props.tenants.filter(x=> x.tenantName === tenantName.value).length > 0
            if(exists) {
                errorMessage.value = "Site already exists";
                return;
            }
            

            emit("on-createTenant", tenantName.value, address.value);
        };

        const clearError = () => {
            errorMessage.value = ""
        }
        
        const onVerifyAddress = async () => {
            try {
                
                emitter.emit("server-call-start", "Verifying address...");
                
                addressModel.value = await apiTenant.verifyAddress(address.value);
                
                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error verifying address", err]);                
            }
            emitter.emit("server-call-stop");            
        }

        onMounted(() => {
            //btnCancel.value.focus();
            siteName.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onCreate();
            if (event.keyCode === 27) onCancel();
        };

        return { 
            btnCancel, 
            onCancel, 
            siteName, 
            onCreate, 
            tenantName, 
            address, 
            errorMessage, 
            clearError, 
            processKeyPress,
            onVerifyAddress,
            addressModel
        };
    }
};
</script>

<style scoped>
.new-tenant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 250px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-tenant-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;    
}

.new-tenant-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor:pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.tenant-input-item {
    width: 300px;
}
</style>
