<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-user">
            <form @submit.prevent="onSubmit" autocomplete="off">
                <div style="display:flex;justify-content:center">New User</div>
                <div style="margin-bottom:10px"><hr /></div>
                <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                    <div class="tenant-input-container">
                        <span>Site</span>
                        <input @focus="clearError" class="tenant-input-item" type="text" v-model="p_tenantName" readonly />
                    </div>
                    <div class="tenant-input-container">
                        <span>Display name</span>
                        <input
                            @focus="clearError"
                            class="tenant-input-item"
                            type="text"
                            v-model="displayName"
                            maxlength="50"
                            :disabled="fetchingData"
                            @keyup="processKeyPress"
                            ref="refUsername"
                            title="Display name of user"
                            aria-label="Display name of user"
                        />
                    </div>
                    <div class="tenant-input-container">
                        <span>Email</span>
                        <input @focus="clearError" class="tenant-input-item" type="email" v-model="username" maxlength="50" :disabled="fetchingData" @keyup="processKeyPress" title="Email of user" aria-label="Email of user" />
                    </div>
                    <div style="color:red;font-size:10pt;text-align:center">
                        {{ errorMessage }}
                    </div>
                    <div class="new-user-button-bar">
                        <button class="new-user-button" @click="onCreate" :disabled="fetchingData" title="Create user" aria-label="Create user">
                            Create User
                        </button>
                        <button class="new-user-button" @click="onCancel" :disabled="fetchingData" title="Cancel new user" aria-label="Cancel new user">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NewUserModel from "@/common/models/NewUserModel";
import Utilities from "@/common/utilities";

export default {
    props: ["tenantId", "tenantName"],
    setup(props, { emit }) {
        const refUsername = ref(null);
        const displayName = ref("");
        const username = ref("");
        const p_tenantName = ref(props.tenantName);
        const errorMessage = ref("");

        const fetchingData = ref(false);

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {
            if (displayName.value.length < 6) {
                errorMessage.value = "Display name must be at least 6 characters";
                return;
            }

            if (displayName.value.length > 50) {
                errorMessage.value = "Display name must be at less than 50 characters";
                return;
            }

            if (!Utilities.IsEmail(username.value)) {
                errorMessage.value = "Email is invalid";
                return;
            }

            var newUser = new NewUserModel();
            newUser.tenantId = props.tenantId;
            newUser.displayName = displayName.value;
            newUser.username = username.value;
            fetchingData.value = true;
            emit("on-createUser", newUser);
        };

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onCreate();
            if (event.keyCode === 27) onCancel();
        };

        const clearError = () => {
            errorMessage.value = "";
        };

        onMounted(() => {
            refUsername.value.focus();
        });

        return { refUsername, onCancel, onCreate, username, p_tenantName, displayName, errorMessage, clearError, fetchingData, processKeyPress };
    }
};
</script>

<style scoped>
/* @media (orientation: landscape) { */
@media (pointer: fine), (pointer: none) {
    .new-user {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -205px;
        margin-left: -255px;
        width: 500px;
        height: 250px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 30px;
        border: 1px solid var(--card-body-border);
    }

    .tenant-input-container {
        display: flex;
        justify-content: space-between;
        margin: 10px;
    }
}

/* @media (orientation: portrait) { */
@media (pointer: coarse)  {    
    .new-user {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20%;
        left: 3%;
        /* margin-top: -205px;
        margin-left: -255px; */
        width: 80vw;
        height: 300px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 30px;
        border: 1px solid var(--card-body-border);
    }

    .tenant-input-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 10px;
    }
}

.new-user-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-user-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}
.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-item {
    width: 300px;
}
</style>
