import axios from "axios";
import ReportSummaryModel from "@/common/models/Reporting/ReportSummaryModel";
import ReportingEarningModel from "@/common/models/Reporting/ReportingEarningModel";
import ReportingCreditCardTotalModel from "@/common/models/Reporting/ReportingCreditCardTotalModel";
import ReportingBucketSizeTotalModel from "@/common/models/Reporting/ReportingBucketSizeTotalModel";
import ReportingKeyValueModel from "@/common/models/Reporting/ReportingKeyValueModel";
import ReportingKeyValueCountModel from "@/common/models/Reporting/ReportingKeyValueCountModel";
import ReportingCashierAuditTrailModel from "@/common/models/Reporting/ReportingCashierAuditTrailModel";
import ReportingCardReaderLogModel from "@/common/models/Reporting/ReportingCardReaderLogModel";
import ReportingCreditCardVoidLogModel from "@/common/models/Reporting/ReportingCreditCardVoidLogModel";
import ReportingDispenseSummaryModel from "@/common/models/Reporting/ReportingDispenseSummaryModel";
import ReportEndOfShiftModel from "@/common/models/Reporting/ReportEndOfShiftModel";
import ReportCashLogModel from "@/common/models/Reporting/ReportCashLogModel";
import ReportMemberPurchaseLogModel from "@/common/models/Reporting/ReportMemberPurchaseLogModel";
import ReportingDispenseSummaryLogModel from "@/common/models/Reporting/ReportingDispenseSummaryLogModel";
import ReportingTransactionActivityLogModel from "@/common/models/Reporting/ReportingTransactionActivityLogModel";
import ReportingSalesModel from "@/common/models/Reporting/ReportingSalesModel";
import ReportingTerminalCashFlowLogModel from "@/common/models/Reporting/ReportingTerminalCashFlowLogModel";
import ReportingIncomeLogModel from "@/common/models/Reporting/ReportingIncomeLogModel";
import ReportInventoryReceivingModel from "@/common/models/Reporting/ReportInventoryReceivingModel";
import ReportingSalesActivityModel from "@/common/models/Reporting/ReportingSalesActivityModel";
import ReportQuickbooksRecordModel from "@/common/models/Reporting/ReportQuickbooksRecordModel";

import ShiftModel from "@/common/models/ShiftModel";

export default class apiReport {
    static async summary(start: Date, end: Date, reportSeriesType: number, quickSummaryOnly: boolean): Promise<Array<ReportSummaryModel>> {
        let models = new Array<ReportSummaryModel>();

        let response = await axios.post("api/Report/Summary", {
            start: start,
            end: end,
            reportSeriesType: reportSeriesType,
            quickSummaryOnly: quickSummaryOnly
        });

        response.data.model.forEach((item: any) => models.push(ReportSummaryModel.from(item)));

        return models;
    }

    static async earningsAtCashier(start: Date, end: Date): Promise<Array<ReportingEarningModel>> {
        let models = new Array<ReportingEarningModel>();

        let response = await axios.post("api/Report/EarningsAtCashier", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingEarningModel.from(item)));

        return models;
    }

    static async earningsAtDispenser(start: Date, end: Date): Promise<Array<ReportingEarningModel>> {
        let models = new Array<ReportingEarningModel>();

        let response = await axios.post("api/Report/EarningsAtDispenser", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingEarningModel.from(item)));

        return models;
    }

    static async earningsAtVendor(start: Date, end: Date): Promise<Array<ReportingEarningModel>> {
        let models = new Array<ReportingEarningModel>();

        let response = await axios.post("api/Report/EarningsAtVendor", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingEarningModel.from(item)));

        return models;
    }

    static async totalsByCreditCardType(start: Date, end: Date): Promise<Array<ReportingCreditCardTotalModel>> {
        let models = new Array<ReportingCreditCardTotalModel>();

        let response = await axios.post("api/Report/TotalsByCreditCardType", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingCreditCardTotalModel.from(item)));

        return models;
    }

    static async totalsByBucketSize(start: Date, end: Date): Promise<Array<ReportingBucketSizeTotalModel>> {
        let models = new Array<ReportingBucketSizeTotalModel>();

        let response = await axios.post("api/Report/TotalsByBucketSize", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingBucketSizeTotalModel.from(item)));

        return models;
    }

    static async totalsByDispenser(start: Date, end: Date): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/TotalsByDispenser", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async cashierAuditTrail(start: Date, end: Date): Promise<Array<ReportingCashierAuditTrailModel>> {
        let models = new Array<ReportingCashierAuditTrailModel>();

        let response = await axios.post("api/Report/CashierAuditTrail", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingCashierAuditTrailModel.from(item)));

        return models;
    }

    static async cardReadLogs(start: Date, end: Date): Promise<Array<ReportingCardReaderLogModel>> {
        let models = new Array<ReportingCardReaderLogModel>();

        let response = await axios.post("api/Report/CardReadLogs", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingCardReaderLogModel.from(item)));

        return models;
    }

    static async creditCardVoids(start: Date, end: Date): Promise<Array<ReportingCreditCardVoidLogModel>> {
        let models = new Array<ReportingCreditCardVoidLogModel>();

        let response = await axios.post("api/Report/CreditCardVoids", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingCreditCardVoidLogModel.from(item)));

        return models;
    }

    static async takingsByCashier(start: Date, end: Date): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/TakingsByCashier", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async ballsDispensedByHour(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/BallsDispensedByHour", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async ballsDispensedByDay(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/BallsDispensedByDay", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async ballsDispensedByWeekDay(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/BallsDispensedByWeekDay", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async ballsDispensedBySource(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/BallsDispensedBySource", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async ballsDispensedByLocation(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingKeyValueModel>> {
        let models = new Array<ReportingKeyValueModel>();

        let response = await axios.post("api/Report/BallsDispensedByLocation", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueModel.from(item)));

        return models;
    }

    static async dispenseSummaryCount(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingDispenseSummaryModel>> {
        let models = new Array<ReportingDispenseSummaryModel>();

        let response = await axios.post("api/Report/DispenseSummaryCount", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingDispenseSummaryModel.from(item)));

        return models;
    }

    static async dispenseSummaryDollars(start: Date, end: Date, getMinutes: boolean): Promise<Array<ReportingDispenseSummaryModel>> {
        let models = new Array<ReportingDispenseSummaryModel>();

        let response = await axios.post("api/Report/DispenseSummaryDollars", {
            start: start,
            end: end,
            getMinutes: getMinutes
        });

        response.data.models.forEach((item: any) => models.push(ReportingDispenseSummaryModel.from(item)));

        return models;
    }

    static async cashLogs(): Promise<Array<ReportCashLogModel>> {
        let models = new Array<ReportCashLogModel>();

        let response = await axios.post("api/Report/CashLogs");

        response.data.models.forEach((item: any) => models.push(ReportCashLogModel.from(item)));

        return models;
    }

    static async getShifts(start: Date, end: Date): Promise<Array<ShiftModel>> {
        let models = new Array<ShiftModel>();

        let response = await axios.post("api/Report/GetShifts", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ShiftModel.from(item)));

        return models;
    }

    static async memberPurchases(start: Date, end: Date): Promise<Array<ReportMemberPurchaseLogModel>> {
        let models = new Array<ReportMemberPurchaseLogModel>();

        let response = await axios.post("api/Report/MemberPurchases", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportMemberPurchaseLogModel.from(item)));

        return models;
    }

    static async dispenserSummaryLogs(start: Date, end: Date): Promise<Array<ReportingDispenseSummaryLogModel>> {
        let models = new Array<ReportingDispenseSummaryLogModel>();

        let response = await axios.post("api/Report/DispenserSummaryLogs", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingDispenseSummaryLogModel.from(item)));

        return models;
    }

    static async transactionActivityLogs(start: Date, end: Date): Promise<Array<ReportingTransactionActivityLogModel>> {
        let models = new Array<ReportingTransactionActivityLogModel>();

        let response = await axios.post("api/Report/DispenserActivityLogs", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingTransactionActivityLogModel.from(item)));

        return models;
    }

    static async endOfShift(shiftId: string): Promise<Array<ReportEndOfShiftModel>> {
        let models = new Array<ReportEndOfShiftModel>();

        let response = await axios.post("api/Report/EndOfShift", {
            shiftId: shiftId
        });

        response.data.models.forEach((item: any) => models.push(ReportEndOfShiftModel.from(item)));

        return models;
    }

    static async endOfDay(username: string, date: string): Promise<Array<ReportEndOfShiftModel>> {
        let models = new Array<ReportEndOfShiftModel>();

        let response = await axios.post("api/Report/EndOfDay", {
            username: username,
            date: date
        });

        response.data.models.forEach((item: any) => models.push(ReportEndOfShiftModel.from(item)));

        return models;
    }

    static async posSales(start: Date, end: Date): Promise<Array<ReportingSalesModel>> {
        let models = new Array<ReportingSalesModel>();

        let response = await axios.post("api/Report/PosSales", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingSalesModel.from(item)));

        return models;
    }

    static async generalLedgerTotals(start: Date, end: Date): Promise<Array<ReportingKeyValueCountModel>> {
        let models = new Array<ReportingKeyValueCountModel>();

        let response = await axios.post("api/Report/GeneralLedgerTotals", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingKeyValueCountModel.from(item)));

        return models;
    }

    static async terminalCashFlow(start: Date, end: Date): Promise<Array<ReportingTerminalCashFlowLogModel>> {
        let models = new Array<ReportingTerminalCashFlowLogModel>();

        let response = await axios.post("api/Report/TerminalCashFlow", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingTerminalCashFlowLogModel.from(item)));

        return models;
    }
    
    static async cashierCashTakings(start: Date, end: Date): Promise<Array<ReportingTerminalCashFlowLogModel>> {
        let models = new Array<ReportingTerminalCashFlowLogModel>();

        let response = await axios.post("api/Report/CashierCashTakings", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingTerminalCashFlowLogModel.from(item)));

        return models;
    }

    static async incomeLogs(start: Date, end: Date): Promise<Array<ReportingIncomeLogModel>> {
        let models = new Array<ReportingIncomeLogModel>();

        let response = await axios.post("api/Report/IncomeLogs", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingIncomeLogModel.from(item)));

        return models;
    }
    
    static async inventoryReceiving(start: Date, end: Date): Promise<Array<ReportInventoryReceivingModel>> {
        let models = new Array<ReportInventoryReceivingModel>();

        let response = await axios.post("api/Report/InventoryReceiving", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportInventoryReceivingModel.from(item)));

        return models;
    }

    static async salesActivity(start: Date, end: Date): Promise<Array<ReportingSalesActivityModel>> {
        let models = new Array<ReportingSalesActivityModel>();

        let response = await axios.post("api/Report/SalesActivity", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportingSalesActivityModel.from(item)));

        return models;
    }
    
    static async quickbookExport(start: Date, end: Date): Promise<Array<ReportQuickbooksRecordModel>> {
        let models = new Array<ReportQuickbooksRecordModel>();

        let response = await axios.post("api/Report/QuickbookExport", {
            start: start,
            end: end
        });

        response.data.models.forEach((item: any) => models.push(ReportQuickbooksRecordModel.from(item)));

        return models;
    }
    
    
}
