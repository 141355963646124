import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportingCardReaderLogModel {
    
    timestamp: string| undefined;
    location: string| undefined;
    item: string| undefined;
    amount: number| undefined;
    status: string| undefined;
    cardType: string| undefined;
    cardNumber: string| undefined;
    cardHolderName: string| undefined;
    transactionResult: string| undefined;
    responseText: string| undefined;
    referenceNumber: string| undefined;
    authorizationCode: string| undefined;
    pathTransactionId: string| undefined;
    refundable: boolean| undefined;
    
      
    
    get amountDisplay(): string {
        return Utilities.toCurrency(this.amount!);
    }

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))                
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()        
    }

    static from(json: string): ReportingCardReaderLogModel {
        let model = Object.assign(new ReportingCardReaderLogModel(), json);
                
        return model;
    }

  }

  
  