export default class ReportSummaryModel {
    manualEntry: number | undefined;
    pinCode: number | undefined;
    rangeCardReloads: number | undefined;
    salesItems: number | undefined;
    doorTurnstile: number | undefined;
    bucketPurchaseCash: number | undefined;

    bucketPurchaseCreditCards: number | undefined;
    bucketPurchaseCreditCardsRefund: number | undefined;
    bucketPurchaseCreditCardsRetro: number | undefined;
    
    bucketPurchaseRangeCardReloads: number | undefined;
    bucketPurchaseRangeCardReloadsRefund: number | undefined;
    bucketPurchaseRangeCardReloadsRetro: number | undefined;

    incomeFromPOSIntegration: number | undefined;
    creditPurchased: number | undefined;
    creditUsed: number | undefined;
    currentTotalBalance: number | undefined;
    currentTotalCustomersWithBalance: number | undefined;
    totalBallsDispensed: number | undefined;
    outstandingPinCode: number | undefined;
    outstandingDoorPinCode: number | undefined;
    
    incomeFromMobileReloads: number | undefined;
    incomeFromMobileReloadsRefund: number | undefined;
    incomeFromMobileReloadsRetro: number | undefined;
    
    incomeFromMobileTBox: number | undefined;
    incomeFromMobileTBoxRefund: number | undefined;
    incomeFromMobileTBoxRetro: number | undefined;
    
    
    incomeFromMobilePincode: number | undefined;
    incomeFromMobilePincodeRefund: number | undefined;
    incomeFromMobilePincodeRetro: number | undefined;

    incomeFromPosCash: number | undefined;
    incomeFromPosCashRefund: number | undefined;
    incomeFromPosCashRetro: number | undefined;

    incomeFromPosGiftCard: number | undefined;

    incomeFromPosCreditCard: number | undefined;
    incomeFromPosCreditCardRefund: number | undefined;
    incomeFromPosCreditCardRetro: number | undefined;

    giftCreditPurchased: number | undefined;
    giftCreditUsed: number | undefined;
    // giftCurrentTotalBalance: number | undefined;

    start: string | undefined;
    end: string | undefined;

    totalMinutesUsed: number | undefined;
    

    cashierRefunds: number | undefined;

    retrospectiveView: boolean = false;

    static from(json: string): ReportSummaryModel {
        let model = Object.assign(new ReportSummaryModel(), json);
                
        return model;
    }

    

    // ------------------------------------------------------------------------------------
    // Income From Dispenser
    // Refunds
    get refundIncomeFromDispenser(): number {         
        if (this.retrospectiveView)
            return 0;
        return this.bucketPurchaseRangeCardReloadsRefund! + this.bucketPurchaseCreditCardsRefund!;
    }

    // Retros
    get bucketPurchaseCreditCardsDisplay(): number {         
        if (!this.retrospectiveView)
            return this.bucketPurchaseCreditCards!;        
        return this.bucketPurchaseCreditCards! + this.bucketPurchaseCreditCardsRetro!;
    }

    get bucketPurchaseRangeCardReloadsDisplay(): number {         
        if (!this.retrospectiveView)
            return this.bucketPurchaseRangeCardReloads!;    
        return this.bucketPurchaseRangeCardReloads! + this.bucketPurchaseRangeCardReloadsRetro!;
    }


    // ------------------------------------------------------------------------------------
    // Income From Mobile App    
    get refundIncomeFromVendor(): number {         
        if (this.retrospectiveView)
            return 0;
        return this.incomeFromMobileReloadsRefund! + this.incomeFromMobilePincodeRefund! + this.incomeFromMobileTBoxRefund! ?? 0;
    }

    get incomeFromMobileReloadsDisplay(): number {         
        if (!this.retrospectiveView)
            return this.incomeFromMobileReloads!;        
        return this.incomeFromMobileReloads! + this.incomeFromMobileReloadsRetro!;
    }

    get incomeFromMobilePincodeDisplay(): number {         
        if (!this.retrospectiveView)
            return this.incomeFromMobilePincode!;        
        return this.incomeFromMobilePincode! + this.incomeFromMobilePincodeRetro!;
    }

    get incomeFromMobileTBoxDisplay(): number {         
        if (!this.retrospectiveView)
            return this.incomeFromMobileTBox! ?? 0;        
        return this.incomeFromMobileTBox! ?? 0 + this.incomeFromMobileTBoxRetro!;
    }
   

    // ------------------------------------------------------------------------------------
    // Income From POS    
    get refundIncomeFromPos(): number {         
        if (this.retrospectiveView)
            return 0;
        return this.incomeFromPosCashRefund! + this.incomeFromPosCreditCardRefund!;
    }

    get incomeFromPosCashDisplay(): number {         
        if (!this.retrospectiveView)
            return this.incomeFromPosCash!;        
        return this.incomeFromPosCash! + this.incomeFromPosCashRetro!;
    }

    get incomeFromPosCreditCardDisplay(): number {         
        if (!this.retrospectiveView)
            return this.incomeFromPosCreditCard!;        
        return this.incomeFromPosCreditCard! + this.incomeFromPosCreditCardRetro!;
    }
    

  }
  