<template>
    <div class="template-base" style="overflow-y: auto;overflow-x: hidden;">
        <Header Title="Vendor Management" class="div-header" />
        <div class="menu-bar vendor-menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newVendor_show = true" :disabled="fetchingData" id="site_management_add_site" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Vendor</div>
                <div>
                    <select class="" v-model="currentVendor" style="width:300px;height:34px" id="site_management_sites">
                        <option v-for="vendor in vendors" :key="vendor.globalId" :value="vendor">{{ vendor.vendorName }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="div-body" v-if="currentVendor != null">
            <div class="setting-item">
                <div class="setting-header">
                    <div>{{ currentVendor.vendorName }}</div>
                    <div class="setting-header-bar"><hr /></div>
                    <div style="display:flex;flex-direction:column">
                        <div class="setting-input-container vendor-input-setting">
                            <span class="setting-name">Username</span>
                            <div style="display:flex; flex-direction:row">
                                <input class="setting-input-item vendor-field" type="text" v-model="currentVendor.username" readonly />
                                <span style="margin-top:2px;margin-left: 10px;cursor: pointer;" title="Copy username" @click="copyToClipboard(currentVendor.username)">
                                    <svg height="20" width="20">
                                        <rect width="12" height="12" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                        <rect width="12" height="12" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div class="setting-input-container vendor-input-setting">
                            <span class="setting-name">Passkey</span>
                            <div style="display:flex; flex-direction:row">
                                <input class="setting-input-item  vendor-field" type="password" v-model="currentVendor.passkey" readonly ref="refPassword" autocomplete="off" />

                                <span style="margin-top:5px;margin-left: 10px;cursor: pointer;" title="Copy passkey" @click="copyToClipboard(currentVendor.passkey)">
                                    <svg height="20" width="20">
                                        <rect width="12" height="12" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                        <rect width="12" height="12" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div class="setting-input-container">
                            <input @change="onSave" type="checkbox" :id="'chkIsEnabled' + currentVendor.globalId" v-model="currentVendor.isEnabled" />
                            <label :for="'chkIsEnabled' + currentVendor.globalId">Active</label>
                        </div>

                        <div class="setting-input-container">
                            <input @change="onSave" type="checkbox" :id="'chkpaymentsEnabled' + currentVendor.globalId" v-model="currentVendor.paymentsEnabled" />
                            <label :for="'chkpaymentsEnabled' + currentVendor.globalId">Payments Enabled</label>
                        </div>

                        <div class="setting-input-container">
                            <input @change="onSave" type="checkbox" :id="'chkrequireEncryptedTenantId' + currentVendor.globalId" v-model="currentVendor.requireEncryptedTenantId" />
                            <label :for="'chkrequireEncryptedTenantId' + currentVendor.globalId">Require Encrypted Tenant Id</label>
                        </div>

                        <div style="margin-left: 40px;" class="setting-input-container" v-if="currentVendor.requireEncryptedTenantId">
                            <div>
                                <input placeholder="search" type="text" style="margin-left: 10px; width:60vw;max-width: 300px;height:22px" required list="siteItems" @change="setTenant" v-model="currentTenant" />
                            </div>
                            <datalist id="siteItems">
                                <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantName"></option>
                            </datalist>
                        </div>
                        <div style="margin-left: 50px;width: 300px;" class="setting-input-container" v-if="currentVendor.requireEncryptedTenantId">
                            <input class="setting-input-item vendor-field" type="password" v-model="tenantIdV" readonly />
                            <span style="margin-top:2px;margin-left: 10px;cursor: pointer;" title="Copy tenantId" @click="copyToClipboard(tenantIdV)">
                                <svg height="20" width="20">
                                    <rect width="12" height="12" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                    <rect width="12" height="12" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NewVendor v-if="newVendor_show" @on-cancel="newVendor_show = false" @on-createVendor="createVendor" :vendors="vendors" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiVendor from "@/api/apiVendor";
import NewVendor from "@/views/components/Shared/NewVendor";
import apiTenant from "@/api/apiTenant";

export default {
    components: { Header, Button, NewVendor },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const vendors = ref([]);
        const tenants = ref([]);
        const newVendor_show = ref(false);
        const currentVendor = ref(null);
        const currentTenant = ref(null);

        const refPassword = ref(null);
        const passKeyAction = ref("Show");
        const tenantIdV = ref("");

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add Vendor...";

        onMounted(() => {
            getData();
        });

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                fetchingData.value = true;
                await apiVendor.save(currentVendor.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const createVendor = async (vendorName) => {
            try {
                emitter.emit("server-call-start", "Creating vendor...");
                fetchingData.value = true;
                var newVendor = await apiVendor.create(vendorName);
                vendors.value.push(newVendor);
                newVendor_show.value = false;

                currentVendor.value = newVendor;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating vendor", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiVendor.getAll();
                vendors.value = items;

                tenants.value = await apiTenant.getTenantList();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const showPassword = async () => {
            if (passKeyAction.value == "Show") {
                refPassword.value.type = "text";
                passKeyAction.value = "Hide";
            } else {
                refPassword.value.type = "password";
                passKeyAction.value = "Show";
            }
        };

        const setTenant = async () => {
            console.log("currentTenant.value", currentTenant.value);
            tenantIdV.value = "";
            try {
                var tenantId = tenants.value.filter((x) => x.tenantName == currentTenant.value)[0].tenantId;
                tenantIdV.value = await apiVendor.encryptTenantId(currentVendor.value.username, tenantId);
            } catch {
                console.log("");
            }
        };

        const copyToClipboard = (data) => {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data + "\n";
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        };

        return {
            btnExit,
            btnAdd,
            vendors,
            newVendor_show,
            createVendor,
            fetchingData,
            currentVendor,
            onSave,
            passKeyAction,
            showPassword,
            refPassword,
            tenants,
            tenantIdV,
            setTenant,
            currentTenant,
            copyToClipboard
        };
    }
};
</script>

<style scoped>
/* @media (orientation: landscape) { */
@media (pointer: fine), (pointer: none) {
    .vendor-field {
        width: 400px;
    }
}

/* @media (orientation: portrait) { */
@media (pointer: coarse)  {    
    .vendor-menu-bar {
        flex-wrap: wrap;
    }

    .vendor-input-setting {
        flex-wrap: wrap;
        width: 60vw;
    }
}
</style>
