import RefundableModel from "@/common/models/RefundableModel";
import RefundModel from "@/common/models/RefundModel";
import axios from "axios";


export default class apiTransaction {


    static async getAll(start: Date): Promise<Array<RefundableModel>> {
        let models = new Array<RefundableModel>();

        let response = await axios.post("api/Transaction/GetAll", {
            start: start,
        });

        response.data.models.forEach((item: any) => models.push(RefundableModel.from(item)));

        return models;
    }


    static async refund(transactionId: string, date: string): Promise<RefundModel> {
        let response = await axios.post("api/Transaction/Refund", {
            transactionId: transactionId,
            date: date,
        });

        let model = RefundModel.from(response.data)
        return model;
    }
}