import Utilities from "@/common/utilities";
import ApiBaseModel from "@/common/models/ApiBaseModel";

export default class PortalUserModel extends ApiBaseModel {
    isVisible: boolean = true;

    username: string = "";
    isDisabled: boolean = false;
    isViewOnly: boolean = false;
    isAdmin: boolean = false;
    isAdminPlus: boolean = false;
    isSuperUser: boolean = false;
    isSuperAdmin: boolean = false;
    isManager: boolean = false;
    displayName: string = "";
    tenant: string = "";
    tenantId: string = "";
    enablePos: boolean = false;
    enableNorthstarPos: boolean = false;
    enableNorthstarApp: boolean = false;
    enableScheduler: boolean = false;
    enableMemberExport: boolean = false;
    enableInventoryManagement: boolean = false;
    private exp: number = 0;

    disableAddCredit: boolean = false;
    disableRangeCardManagement: boolean = false;
    disableCustomerManagement: boolean = false;
    disableCustomerCategory: boolean = false;
    disableHotKeys: boolean = false;
    disableKiosk: boolean = false;
    disablePinTicket: boolean = false;
    disablePreferences: boolean = false;
    disableUserRights: boolean = false;
    disableManualTbox: boolean = false;
    disableInventoryManagement: boolean = false;
    enableRefundManagement: boolean = false;
    cardpointeEndpoint: string = ""

    version: string = "";
    role: string = "";
    session: string = "";
    timezone: string = "";
    mfaRequired: boolean = false;
    overridePin: string = "";

    inactiveTimeout: number = 20;
    cardReaderThreshold: number = 20;
    cashierOverrideFont: string = '';

    locale: string = ""
    currency: string = ""

    public IsValid(): boolean {
        if (Date.now() < this.exp * 1000) {
            return true;
        }

        return false;
    }

    static fromToken() {
        var token = localStorage.getItem("token");
        let payload = Utilities.parseJwt(token || "{}");        

        let user = new PortalUserModel();
        user.username = payload.email;
        user.isAdmin = payload.isAdmin === "true";
        user.isAdminPlus = payload.isAdminPlus === "true";    
        user.isSuperAdmin = payload.isSuperAdmin === "true";
        user.isManager = payload.isManager === "true";
        user.displayName = payload.displayName;
        user.tenant = payload.tenant;
        user.tenantId = payload.tenantId;
        user.enablePos = payload.enablePos === "true";
        user.enableNorthstarPos = payload.enableNorthstarPos === "true";
        user.enableNorthstarApp = payload.enableNorthstarApp === "true";
        user.enableInventoryManagement = payload.enableInventoryManagement === "true";
        user.enableScheduler = payload.enableScheduler === "true";
        user.enableMemberExport = payload.enableMemberExport === "true";
        user.isSuperUser = payload.isSuperUser === "true";

        user.exp = payload.exp;



        user.disableAddCredit = payload.disableAddCredit === "true";
        user.disableRangeCardManagement = payload.disableRangeCardManagement === "true";
        user.disableCustomerManagement = payload.disableCustomerManagement === "true";
        user.disableCustomerCategory = payload.disableCustomerCategory === "true";
        user.disableHotKeys = payload.disableHotKeys === "true";
        user.disableKiosk = payload.disableKiosk === "true";
        user.disablePinTicket = payload.disablePinTicket === "true";
        user.disablePreferences = payload.disablePreferences === "true";
        user.disableUserRights = payload.disableUserRights === "true";
        user.disableManualTbox = payload.disableManualTbox === "true";
        user.disableInventoryManagement = payload.disableInventoryManagement === "true";
        user.enableRefundManagement = payload.enableRefundManagement === "true";

        user.version = payload.version
        user.role = payload.role
        user.session = payload.sessionId
        user.timezone = payload.timezone
        user.inactiveTimeout = payload.inactiveTimeout
        user.cardReaderThreshold = payload.cardReaderThreshold
        user.cashierOverrideFont = payload.cashierOverrideFont
        user.cardpointeEndpoint = payload.cardpointeEndpoint

        user.locale = payload.locale
        user.currency = payload.currency

        return user;
    }

    static getPayLoad() {
        var token = localStorage.getItem("token");
        return Utilities.parseJwt(token || "{}");
    }

    static from(json: string): PortalUserModel {
        let model = Object.assign(new PortalUserModel(), json);
        return model;
    }
}
